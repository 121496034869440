import request from './request.js'
export function partnerList(params) {
    return request({
        url: "/partner/list.do?",
        method: 'post',
        data: params
    })
}
export function enterpriseList(params) {
    return request({
        url: "/partner/enterpriseList.do?",
        method: 'post',
        data: params
    })
}
export function getRewardDetails(params) {
    return request({
        url: "/tob/tobRebate/list.do?",
        method: 'post',
        data: params
    })
}

export function editRemark(params) {
    return request({
        url: "/tob/tobRebate/updateRemark.do?",
        method: 'post',
        data: params
    })
}
export function payList(params) {//返利付款列表
    return request({
        url: "/xiaolu/pay/list.do?",
        method: 'post',
        data: params
    })
}
export function payRecordList(params) {  //提现记录
    return request({
        url: "/xiaolu/pay/record/list.do?",
        method: 'post',
        data: params
    })
}
export function payTransfer(params) {  //返利打款
    return request({
        url: "/xiaolu/pay/transfer.do?",
        method: 'post',
        data: params
    })
}

export function recordRetry(params) {  //重试
    return request({
        url: "/xiaolu/pay/record/retry.do?",
        method: 'post',
        data: params
    })
}

export function cancelPay(params) {  //取消
    return request({
        url: "/xiaolu/pay/record/cancelPay.do?",
        method: 'post',
        data: params
    })
}

export function partnerRewardMonthList(params) {  //获取提现审核列表
    return request({
        url: "/tob/partner/reward/partnerRewardMonthList.do?",
        method: 'post',
        data: params
    })
}
export function rewardAuth(params) {  //提现审核
    return request({
        url: "/tob/partner/reward/rewardAuth.do?",
        method: 'post',
        data: params
    })
}

export function batchEdit(params) {  //  批次编辑接口
    return request({
      url: 'chargeGroupBatch/update.do?',
      method: 'post',
      data: params
  })
}
export function delBatchCardById(params) { //  批次卡删除
  return request({
      url: 'chargeGroupBatch/delete.do?',
      method: 'post',
      data: params
  })
}
export function chargeGroupBatchList(params) {  //  批次卡列表
  return request({
    url: 'chargeGroupBatch/list.do?',
    method: 'post',
    data: params
})
}
export function exportExcelAsync(params) {
    return request({
        url: "/tob/tobRebate/exportExcelAsync.do?",
        method: 'post',
        data: params
    })
}

export function getOrderReviewList(params) {
    return request({
        url: "/tob/tobRebateAuth/list.do?",
        method: 'post',
        data: params
    })
}

export function orderExportExcelAsync(params) {
    return request({
        url: "/tob/tobRebateAuth/exportExcelAsync.do?",
        method: 'post',
        data: params
    })
}

export function authTobRebateAuth(params) {
    return request({
        url: "/tob/tobRebateAuth/auth.do?",
        method: 'post',
        data: params
    })
}

export function updateRemark(params) {
    return request({
        url: "/tob/tobRebateAuth/updateRemark.do?",
        method: 'post',
        data: params
    })
}


















